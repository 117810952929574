<template>
    <!-- <div class="countdown">{{ `${day} ${hr}小时 ${min}分 ${sec}秒` }}</div> -->
    <div class="countdown">{{ (day>0?day+txt[0]+' ':'')+ hr+txt[1]+' ' +min+txt[2]+' '+ sec+txt[3] }}</div>
</template>

<script>
export default {
  name: 'countdown',
  props: {
    endTimestamp: {
      type: Number
    },
    txt: {
        type: Array,
        default: ()=>{
            return ['d','h','m','s']
        }
    }
  },
  mounted () {
    this.countdown()
  },
  destroyed () {
    clearTimeout(this.timer)
  },
  methods: {
    countdown () {
      const end = this.endTimestamp
      const now = Date.parse(new Date())
      const msec = end - now
      if (msec <= 0) {
        this.day = 0
        this.hr = 0
        this.min = 0
        this.sec = 0
        clearTimeout(this.timer)
        this.$emit('end')
        return
      }
      let day = parseInt(msec / 1000 / 60 / 60 / 24)
      let hr = parseInt(msec / 1000 / 60 / 60 % 24)
      let min = parseInt(msec / 1000 / 60 % 60)
      let sec = parseInt(msec / 1000 % 60)
      this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      const that = this
      this.timer = setTimeout(function () {
        that.countdown()
      }, 1000)
    }
  },
  data () {
    return {
      timer: null,
      day: '',
      hr: '',
      min: '',
      sec: ''
    }
  }
}
</script>

<style lang="less" scoped>

</style>
