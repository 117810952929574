<template>
  <div class=" grounds">
    <a-icon class="close-tag" @click="handleClose" type="close-circle" />

    <div class="title"> {{ $t('budhi.groundTitle') }} </div>

    <a-row :gutter="16" class="list">
      <a-col :span="8" v-for="(item,index) in groundList" :key="index">
          <div class="box item" :class="index==currentIndex?'on':''" @click="selectGround(index)">
              <div class="img">
                <img src="@/assets/svgs/ground.svg" class="icon" />
              </div>
              <span>{{ item[0] + $t('budhi.groundUnit') }}</span>
              <div class="price">{{ item[1] }}U</div>
          </div>
      </a-col>

    </a-row>
    <div>
      <div class="bct-mixed">
        <div class="item" :class="payType==0?'on':''" @click="checkPayType(0)">使用USDT</div>
        <div v-if="payTypeSetting.on" class="item" :class="payType==1?'on':''" @click="checkPayType(1)">使用BCT对冲</div>
      </div>
      <div class="mg30 tips">
        <div class="color-gray tip" v-if="payType==0"> 使用USDT支付 </div>
        <div class="color-gray tip" v-if="payTypeSetting.on && payType==1"> 使用{{payTypeSetting.rate[0]}}%USDT + {{payTypeSetting.rate[1]}}%BCT支付 </div>
      </div>
    </div>
    <div class="btn-primary" @click="pay">支付</div>
    <div v-if="payType==1" class="mg30 btn-primary-ghost" @click="$router.push({name:'mrecords'})">歷史訂單</div>
  </div>
</template>

<script>
import Shelldao from "@/web3/tron/shelldao";
const DONATE_TYPE_GROUND = 1; //菩提園捐贈類型為購買土地
const DONATE_TYPE_GROUND_UP = 3; ////菩提園捐贈類型為升级土地
const DONATE_TYPE_PAY_GROUND = 5; //菩提園捐贈類型為對衝購買土地
const DONATE_TYPE_PAY_GROUND_UP = 7; ////菩提園捐贈類型為對衝升级土地
export default {
  name: 'Grounds',
  data () {
    return {
      loading: false,
      payType: 0,
      payTypeSetting: {on:false,rate:[0,0]},
      userUsdtBalance: 0,
      approvedUsdtAmount: 0,
      currentIndex: 0,
      tips:'',
      groundList: []
    }
  },
  props: {
    isUpgrade: {
      type: Boolean,
      default: false
    },
    upgradeBaseAmount: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    Shelldao.init((r)=>{
      r.gUsdtToken.balanceOf(r.gWalletAddress).call().then((res)=>{
        this.userUsdtBalance = res.toNumber() / 1000000;
        console.log('usdt balance:', this.userUsdtBalance);
      });
      Shelldao.getBudhiApprovedAmount((res)=>{
        let _approvedAmount=0;
        if(res.remaining===undefined){
            _approvedAmount = res.toNumber();
        }else{
            _approvedAmount = res.remaining.toNumber();
        }
        this.approvedUsdtAmount = _approvedAmount/1000000;
        console.log('approved usdt:', this.approvedUsdtAmount);
      });
    });
    this.getGrounds();
    this.getPayType();
  },
  destroyed () {
  },
  methods: {
    handleClose(){
      this.$emit('close');
    },
    getGrounds() {
      let _this = this
      this.$http.call(this.$http.api.grounds).then(res=>{
          // console.log(res.data.data);
          if(res.data.code == '200'){
            _this.groundList = res.data.data;
          }else{
            _this.$message.info(res.data.data)
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    getPayType() {
      let _this = this
      this.$http.call(this.$http.api.payType).then(res=>{
          console.log(res.data.data);
          if(res.data.code == '200'){
            _this.payTypeSetting = res.data.data;
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    checkPayType(t) {
      this.payType = t;
    },
    selectGround(i) {
      this.currentIndex = i;
    },
    handleDonate(ground, index){
      console.log(ground)
      let _this = this;
      let _donate_type = DONATE_TYPE_GROUND;
      let _amount = ground[1]*1000000;
      if(this.isUpgrade){
        let _upgradeBaseAmount = 0;
        this.groundList.forEach((item)=>{
          console.log(item[0], this.upgradeBaseAmount)
          if(item[0] == this.upgradeBaseAmount){
            _upgradeBaseAmount = item[1];
          }
        })
        console.log(_upgradeBaseAmount, ground[1])
        if(1*_upgradeBaseAmount >= 1*ground[1]){
          this.$message.info('无需升级');return;
        }
        _amount = (ground[1] - _upgradeBaseAmount)*1000000;
        _donate_type = DONATE_TYPE_GROUND_UP;
      }
      if(this.approvedUsdtAmount*1000000 <= _amount){
        this.loading = true;
        Shelldao.budhiApproveToken(_amount*10, (res)=>{
          console.log(res)
          Shelldao.budhiDonate(_amount, _donate_type, index+1, ()=>{
            _this.$message.success('success');
            _this.loading = false;
          });
        });
      }else{
        Shelldao.budhiDonate(_amount, _donate_type, index+1, ()=>{
          _this.$message.success('success');
          _this.loading = false;
        });
      }
      
    },
    pay(){
      if(this.payType==1){
        console.log('pay by bct')
        let _this = this
        _this.loading = true
        let ground = this.groundList[this.currentIndex];
        console.log(ground)
        let t = this.isUpgrade?DONATE_TYPE_PAY_GROUND_UP:DONATE_TYPE_PAY_GROUND
        let data = {
          amount: ground[1],
          t,
          i: this.currentIndex+1,
          upgrade: this.isUpgrade
        }
        console.log(data)
        this.$http.call(this.$http.api.mixPay, {data}).then(res=>{
            console.log(res.data.data);
            if(res.data.code == '200'){
              let _d = res.data.data;
              if(_this.approvedUsdtAmount <= _d.amount){
                _this.loading = true;
                Shelldao.budhiApproveToken(_d.amount*10, (res)=>{
                  console.log(res)
                  Shelldao.budhiDonate(_d.amount, _d.t, _d.i, ()=>{
                    _this.$message.success('success');
                    _this.loading = false;
                  });
                });
              }else{
                Shelldao.budhiDonate(_d.amount, _d.t, _d.i, ()=>{
                  _this.$message.success('success');
                  _this.loading = false;
                });
              }
            }else{
              _this.$message.info(res.data.data);
            }
        },res=>{
            // console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(res=>{
          console.log(res)
          _this.loading = false
        });
      }else{
        this.handleDonate(this.groundList[this.currentIndex], this.currentIndex);
      }
    }
  },
  
}
</script>

<style lang="less" scoped>
  .grounds{
    width: 100%;
    padding: 15PX;
    border-radius: 15PX;
    color:#333;
    position: relative;
    background: #dadada;
    .close-tag {
      position: absolute;
      top: 15PX; right: 15PX;
    }
    .title {
      margin-bottom: 15PX;
    }
    .list {
      .item {
        padding: 15PX;
        margin-bottom: 15PX;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .img {
          .icon {
            height: 38PX;
          }
        }
        .price {
          padding-top: 7PX;
        }
      }.on {
        background: @primary-color;
        color: #fff;
        font-weight: bold;
      }
    }
    .bct-mixed {
      display: flex;
      justify-content: space-around;
      margin: 15px auto;
      .item {
        // padding-left: 80px;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
          display: block;
          content: '';
          width: 30px;
          height: 30px;
          margin-right: 15px;
          border-radius: 50%;
          background: transparent;
          border: 1px solid #333;
        }
      }.on {
        color: @primary-color;
        font-weight: bold;
        &::before {
          background: @primary-color;
          
        }
      }
    }
  }
</style>
