<template>
    <div class="budhi">
      <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left-circle" />
        <div class="title">
          <span class="text">{{ $t('home.navTitles.budhi') }}</span>
        </div>
      </div>
      <div v-if="userGround.path" class="user-ground">
        <div v-if="userGround.path=='ground'" class="box my-ground">
          <a-icon class="refresh" @click="$router.go(0)" type="sync" />
          <img src="@/assets/svgs/ground.svg" class="icon" />
          <div class="id-tag">#{{ userGround.data.id }}</div>
          <div class="text">
            <div class="ground-area">面積：{{ userGround.data.area + $t('budhi.groundUnit') }}</div>
            <div class="ground-bd">佛力指數：{{ userGround.data.bd_idx }}</div>
            <div class="ground-rate">管理費：{{ userGround.data.fee }}%</div>
          </div>
          <div class="btns">
            <div class="btn-primary btn-sm upgrade" @click="upgrade(0)">
              <a-icon type="arrow-up" />升級
            </div>
          </div>
          
        </div>
        <div v-if="userGround.path=='sp'" class="box my-sp">
          <a-icon class="refresh" @click="$router.go(0)" type="sync" />
          <img src="@/assets/svgs/sp.svg" class="icon" />
          <div class="text">
            <div>{{ $t('budhi.sps')[userGround.data.sp_id] }}</div>
            <div class="ground-area">功德：{{ userGround.data.money }}U</div>
            <div class="ground-bd">佛力指數：{{ userGround.data.bd_idx }}</div>
          </div>
          <div class="flex btns">
            <div class="btn-primary btn-sm buy-ground-btn" @click="upgrade(0)">
              <img src="@/assets/svgs/ground.svg" class="btn-icon" />购买土地
            </div>
            <div class="btn-primary btn-sm upgrade" @click="upgrade(1)">
              <a-icon type="arrow-up" />升級修行
            </div>
          </div>
        </div>
        <div v-if="!showModal" class="box budhi-tree">
          <div class="flex column plant-box" v-if="!userBudhiTree">
            <!-- <div class="ugrounds" v-if="activeGroundId>0"> -->
              <!-- <div class="uground-box">
                <img src="@/assets/svgs/ground.svg" class="plant-box-ground-icon" />
                <div class="ground-id-tag">#{{ activeGroundId }}</div>
              </div> -->
              <div v-if="activeGroundId>0" class="ground-road-card">
                <!-- <img src="@/assets/imgs/road_card.png" /> -->
                <div class="ground-id-tag">#{{ activeGroundId }}</div>
                <div class="bct-symbal">{{ $t('common.currency') }}</div>
              </div>
            <!-- </div> -->
            <div v-else class="no-grounds">
              暂时没有可用土地
            </div>
            <!-- <div><img src="@/assets/imgs/tree/tree_ground.png" alt=""></div> -->
            <div class="mg30 btn-primary btn-sm" @click="plant">种植菩提树</div>
          </div>
          <div class="flex column tree-img" v-else>
            <img class="budhi-tree-img" :src="'/statics/imgs/tree/budhi-tree'+treePicIdx(userBudhiTree.water_time)+'.png'" alt="">
            <!-- <div><img src="@/assets/imgs/tree/tree_04.png" alt=""></div> -->
            <div v-if="userBudhiTree.water_time" class="mg30 progress-container">
              <!-- <div class="mg30">上次浇水：{{ (userBudhiTree.water_time) }}</div> -->
              <a-progress
                :stroke-color="{
                  '0%': '#d8d8d8',
                  '100%': '#b37e25',
                }"
                :showInfo="false"
                :percent="ripeProgress(userBudhiTree.water_time)"
              />
              <div class="mg30 predict-profit">
                预计可获得{{ userBudhiTree.predictProfit }}{{ $t('common.currency') }}
              </div>
              <div v-if="(new Date()).getTime() < (new Date(userBudhiTree.water_time)).getTime()+86400000" class="countdown">
                <Countdown class="count-down" :endTimestamp="ripeTime(userBudhiTree.water_time)" @end="getUserBudhiTree()" /><br/>
              </div>
              
              <!-- <div>
                {{ ripeProgress(userBudhiTree.water_time) }}%
              </div> -->
            </div>
            <div v-if="isRipe(userBudhiTree.water_time)">
              <div class="btn-primary btn-sm" @click="harvest">采摘</div>
            </div>
            <div v-if="userBudhiTree.progress==0" class="mg30 btn-primary btn-sm" @click="waterTree">浇水</div>
            
          </div>
          <!-- <div class="btns">
            <div class="btn-primary btn-sm btn-sm">种植</div>
          </div> -->
        </div>
      </div>
      <div class="content">
        <div v-if=" !userGround.path" class="flex boxes">
          <div class="box" @click="handleBuyGround">
            <img src="@/assets/svgs/ground.svg" class="icon" />
            <div class="title">
              {{ $t('budhi.groundTitle') }}
            </div>
          </div>
          <div class="box" @click="handleSps">
            <img src="@/assets/svgs/sp.svg" class="icon" />
            <div class="title">
              {{ $t('budhi.spTitle') }}
            </div>
          </div>
        </div>
      </div>
      <Grounds v-if="showGroundsBox" class="grounds-list" :isUpgrade="isUpgrade" :upgradeBaseAmount="upgradeBaseAmount" @close="closeGroundsBox" />
      <SpiritualPractice v-if="showSpsBox" class="sps-list" :isUpgrade="isUpgrade" :upgradeBaseAmount="upgradeBaseAmount" @close="closeSpsBox" />

    </div>
</template>
<script>
import Grounds from '@/components/Grounds.vue';
import SpiritualPractice from '@/components/SpiritualPractice.vue';
import Countdown from '@/components/Countdown.vue';
export default {
  name: 'Budhi',
  data(){
    return {
      showModal: false,
      showGroundsBox: false,
      showSpsBox: false,
      isUpgrade: false,
      upgradeBaseAmount: 0,
      toPlantGroundId: 0,
      activeGroundId: 0,
      userGround: {
        path: ''
      },
      userBudhiTree: null
    }
  },
  components:{
      Grounds, SpiritualPractice, Countdown
  },
  mounted(){
    this.getGround();
    this.getUserBudhiTree();
    this.getUgrounds();
  },
  methods:{
    treePicIdx(waterTime){
      if(!waterTime) return 0;
      return Math.min((Math.ceil(this.ripeProgress(waterTime)/33)),3)
    },
    isRipe(waterTime){
      if(!waterTime) return false;
      let ripeTime = new Date(waterTime);
      ripeTime.setDate(ripeTime.getDate() + 1);
      ripeTime.setHours(0,0,0,0);
      let date = new Date();
      return date > ripeTime;
    },
    ripeTime(waterTime) {
      let ripeTime = new Date(waterTime);
      ripeTime.setDate(ripeTime.getDate() + 1);
      ripeTime.setHours(0,0,0,0);
      ripeTime = ripeTime.getTime();
      return ripeTime;
    },
    ripeProgress(waterTime){
      let ripeTime = new Date(waterTime);
      ripeTime.setDate(ripeTime.getDate() + 1);
      ripeTime.setHours(0,0,0,0);
      ripeTime = ripeTime.getTime();
      let _dtime = (new Date()).getTime();

      return _dtime>ripeTime?100:Math.round((86400000 - ripeTime + _dtime)/864000);
    },
    handleBuyGround(){
      this.showModal = true;
      this.showGroundsBox = true;
      this.showSpsBox = false;
    },
    handleSps(){
      this.showModal = true;
      this.showSpsBox = true;
      this.showGroundsBox = false;

    },
    closeGroundsBox(){
      this.showGroundsBox = false;
      this.showModal = false;
    },
    closeSpsBox(){
      this.showSpsBox = false;
      this.showModal = false;
    },
    getGround() {
      let _this = this
      this.$http.call(this.$http.api.uground).then(res=>{
          console.log(res.data);
          if(res.data.code == '200'){
            _this.userGround = res.data.data;
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    getUserBudhiTree(){
      let _this = this
      this.$http.call(this.$http.api.userBudhitree).then(res=>{
          console.log(res.data);
          if(res.data.code == '200'){
            _this.userBudhiTree = res.data.data;
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    waterTree(){
      let _this = this
      this.$http.call(this.$http.api.waterTree).then(res=>{
          console.log(res.data);
          if(res.data.code == '200'){
            _this.userBudhiTree = res.data.data;
            _this.getUserBudhiTree();
          }else{
            _this.$message.info(res.data.data);
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    harvest(){
      let _this = this
      this.$http.call(this.$http.api.harvest).then(res=>{
          console.log(res.data.data);
          _this.$message.info(res.data.data);
          _this.getUserBudhiTree();
          // if(res.data.code == '200'){
          //   _this.userBudhiTree = res.data.data;
          // }else{
          //   _this.$message.info(res.data.data);
          // }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    getUgrounds() {
      let _this = this
      this.$http.call(this.$http.api.ugrounds).then(res=>{
          console.log(res.data.data);
          if(res.data.code == '200'){
            _this.activeGroundId = res.data.data.ground_id;
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    upgrade(i){
      console.log('upgrade')
      this.isUpgrade = true;
      if(i==0){
        this.upgradeBaseAmount = this.userGround.data.area;
        this.showModal = true;
        this.showGroundsBox = true;
        this.showSpsBox = false;

      }else{
        this.upgradeBaseAmount = this.userGround.data.money;
        this.showModal = true;
        this.showSpsBox = true;
        this.showGroundsBox = false;

      }
      // this.$message.info('暫未開啟');
    },
    plant(){
      if(this.activeGroundId==0){
        this.$message.info('请选择土地');return;
      }
      let _this = this;
      let data = {
        ground_id: this.activeGroundId
      };
      this.$http.call(this.$http.api.plant,{data}).then(res=>{
          console.log(res.data);
          if(res.data.code == '200'){
            // _this.userGround = res.data.data;
            _this.userBudhiTree = res.data.data;
          }else{
            _this.$message.info(res.data.data)
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    }
  }
}
</script>
<style lang="less">
.budhi{
  padding: 30px 50px;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  .header {
    position: relative;
    width: 100%;
    color: #fff;
    .back {
        position: absolute;
        left: 0;
        font-size: 20PX;
    }
    .title {
      text-align: center;
      font-weight: bold;
      font-size: 16PX;
    }
  }
  .content {
    width: 100%;
    position: relative;
    display: flex;
    margin-bottom: 24px;
    padding-top: 15PX;
    .boxes{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .box{
        background: #dadada;
        color: @primary-color;
        padding: 20PX;
        // margin: 1PX auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          height: 64PX;
        }
        .title {
          color: @primary-color;
          font-weight: bold;
          padding-top: 7PX;
        }
      }
    }
  }
  .user-ground {
    // display: flex;
    // flex: 1;
    // flex-direction: column;
    .btn-sm {
      padding: 7PX;
      font-size: 14PX;
      letter-spacing: 0;
    }
    .box {
      margin: 15PX auto;
      width: 100%;
      padding: 15PX;
      position: relative;
      overflow: hidden;
      .id-tag {
        position: absolute;
        top: 0;
        left: 0;
        background: @primary-color;
        color: #fff;
        border-bottom-right-radius: 15PX;
        padding: 0 15PX;
      }
      .btns {
        margin-top: 15PX;
        display: flex;
        justify-content: flex-end;
        .upgrade {
          display: inline-block;
        }
      }
      .refresh {
        position: absolute;
        top: 15PX; right: 15PX;
      }
      
    }
    .my-sp {
      .btns {
        .buy-ground-btn {
          margin-right: 7PX;
          .btn-icon {
            height: 1em;
          }
        }
      }
    }
    .budhi-tree {
      flex: 1;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;
      align-items: center;
      background-image: url('~@/assets/imgs/tree_bg.jpg');
      background-position: center;
      background-size: cover;
      .budhi-tree-img {
        max-width: 80%;
      }
      .progress-container {
        width: 80%;
        .count-down {
          display: inline-block;
        }
      }
      
      .plant-box {
        flex: 1;
        justify-content: space-between;
        // min-height: 500px;
        // .ugrounds {
          // display: flex;
          // flex-wrap: wrap;
          // min-height: 500px;
          // .uground-box {
          //   margin: 10px;
          //   padding: 30px 30px 50px; border-radius: 15PX;
          //   background: #dfdfdf;
          //   position: relative;
          //   overflow: hidden;
          //   .plant-box-ground-icon {
          //     opacity: .3;
          //   }
          //   .ground-id-tag {
          //     position: absolute;
          //     bottom: 0; left: 0;
          //     background: #33333333;
          //     padding: 5px 0;
          //     font-size: 36px;
          //     width: 100%;
          //   }
          // }.on{
          //   background: @primary-color;
          //   color: #fff;
          // }

        // }
        .ground-road-card {
          margin-top: 150px;
          width: 88PX; height: 88PX;
          background-image: url('~@/assets/imgs/road_card.png');
          background-position: center;
          background-size: cover;
          position: relative;
          color: @primary-color;
          text-shadow: 2px 2px 4px #dadada;
          font-weight: bold;

          .ground-id-tag {
            position: absolute;
            top: 9PX; left: 24PX;
            transform: rotate(-8deg);
          }
          .bct-symbal {
            position: absolute;
            top: 34PX; left: 26PX;
            transform: rotate(-5deg);
          }
        }
        .no-grounds {
          padding: 30px 0;
          min-height: 200px;
        }
      }
    }
  }
  
}

</style>
