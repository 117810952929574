<template>
  <div class="sps">
    <a-icon class="close-tag" @click="handleClose" type="close-circle" />

    <div class="title"> {{ $t('budhi.spTitle') }} </div>

    <a-row :gutter="16" class="list">
      <a-col :span="8" v-for="(item,index) in spList" :key="index">
          <div class="box item" :class="index==currentIndex?'on':''" @click="selectSp(index)">
              <div class="img">
                <img src="@/assets/svgs/sp.svg" class="icon" />
              </div>
              <span>{{ item[1] }}</span>

              <div class="price">{{ item[2] }}U</div>
          </div>
      </a-col>
    </a-row>
    <div>
      <div class="bct-mixed">
        <div class="item" :class="payType==0?'on':''" @click="checkPayType(0)">使用USDT</div>
        <div v-if="payTypeSetting.on" class="item" :class="payType==1?'on':''" @click="checkPayType(1)">使用BCT对冲</div>
      </div>
      <div class="mg30 tips">
        <div class="color-gray tip" v-if="payType==0"> 使用USDT支付 </div>
        <div class="color-gray tip" v-if="payTypeSetting.on && payType==1"> 使用{{payTypeSetting.rate[0]}}%USDT + {{payTypeSetting.rate[1]}}%BCT支付 </div>
      </div>
    </div>
    <div class="btn-primary" @click="pay">支付</div>
    <div v-if="payType==1" class="mg30 btn-primary-ghost" @click="$router.push({name:'mrecords'})">歷史訂單</div>
  </div>
</template>

<script>
// spiritual practice
import Shelldao from "@/web3/tron/shelldao";
const DONATE_TYPE_SP = 2; //菩提園捐贈類型為進場修行
const DONATE_TYPE_SP_UP = 4; ////菩提園捐贈類型為升级修行
const DONATE_TYPE_PAY_SP = 6; //菩提園捐贈類型為對衝進場修行
const DONATE_TYPE_PAY_SP_UP = 8; ////菩提園捐贈類型為對衝升级修行
export default {
  name: 'SpiritualPractice',
  props: {
    isUpgrade: {
      type: Boolean,
      default: false
    },
    upgradeBaseAmount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loading: false,
      spList: [],
      userUsdtBalance: 0,
      approvedUsdtAmount: 0,
      tips:'',
      payType: 0,
      currentIndex: 0,
      payTypeSetting: {on:false,rate:[0,0]},
    }
  },
  mounted () {
    Shelldao.init((r)=>{
      r.gUsdtToken.balanceOf(r.gWalletAddress).call().then((res)=>{
        this.userUsdtBalance = res.toNumber() / 1000000;
        console.log('usdt balance:', this.userUsdtBalance);
      });
      Shelldao.getBudhiApprovedAmount((res)=>{
        let _approvedAmount=0;
        if(res.remaining===undefined){
            _approvedAmount = res.toNumber();
        }else{
            _approvedAmount = res.remaining.toNumber();
        }
        this.approvedUsdtAmount = _approvedAmount/1000000;
        console.log('approved usdt:', this.approvedUsdtAmount);
      });
    });
    this.getSps();
    this.getPayType();
  },
  destroyed () {
  },
  methods: {
    handleClose(){
      this.$emit('close');
    },
    getSps() {
      let _this = this
      this.$http.call(this.$http.api.sps).then(res=>{
          console.log(res.data);
          if(res.data.code == '200'){
            _this.spList = res.data.data;
          }else{
            _this.$message.info(res.data.data)
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    selectSp(i) {
      this.currentIndex = i;
    },
    handleDonate(sp, sp_id){
      let _this = this;
      let _donate_type = DONATE_TYPE_SP;
      let _amount = sp[2]*1000000;
      if(this.isUpgrade){
        if(this.upgradeBaseAmount >= sp[2]){
          this.$message.info('无需升级');return;
        }
        _amount = (sp[2] - this.upgradeBaseAmount)*1000000;
        _donate_type = DONATE_TYPE_SP_UP;
      }
      if(this.approvedUsdtAmount*1000000 <= _amount){
        this.loading = true;
        Shelldao.budhiApproveToken(_amount*10, (res)=>{
          console.log(res)
          Shelldao.budhiDonate(_amount, _donate_type, sp_id, ()=>{
            _this.$message.success('success');
            _this.loading = false;
          });
        });
      }else{
        Shelldao.budhiDonate(_amount, _donate_type, sp_id, ()=>{
          _this.$message.success('success');
          _this.loading = false;
        });
      }
      
    },
    getPayType() {
      let _this = this
      this.$http.call(this.$http.api.payType).then(res=>{
          // console.log(res.data.data);
          if(res.data.code == '200'){
            _this.payTypeSetting = res.data.data;
          }
      },res=>{
          // console.log('something goes wrong...');
          console.log(res.response);
          if(res.response.status == '401'){
              this.$store.commit('setToken','');
              this.$router.push({name:'login'});
          }
      });
    },
    checkPayType(t) {
      this.payType = t;
    },
    pay(){
      if(this.payType==1){
        console.log('pay by bct')
        let _this = this
        _this.loading = true
        let sp = this.spList[this.currentIndex];
        console.log(sp)
        let t = this.isUpgrade?DONATE_TYPE_PAY_SP_UP:DONATE_TYPE_PAY_SP
        let data = {
          amount: sp[2],
          t,
          i: sp[0],
          upgrade: this.isUpgrade
        }
        console.log(data)
        this.$http.call(this.$http.api.mixPay, {data}).then(res=>{
            console.log(res.data.data);
            if(res.data.code == '200'){
              let _d = res.data.data;
              if(_this.approvedUsdtAmount*1000000 <= _d.amount){
                _this.loading = true;
                Shelldao.budhiApproveToken(_d.amount*10, (res)=>{
                  console.log(res)
                  Shelldao.budhiDonate(_d.amount, _d.t, _d.i, ()=>{
                    _this.$message.success('success');
                    _this.loading = false;
                  });
                });
              }else{
                Shelldao.budhiDonate(_d.amount, _d.t, _d.i, ()=>{
                  _this.$message.success('success');
                  _this.loading = false;
                });
              }
            }else{
              _this.$message.info(res.data.data);
            }
        },res=>{
            // console.log('something goes wrong...');
            console.log(res.response);
            if(res.response.status == '401'){
                this.$store.commit('setToken','');
                this.$router.push({name:'login'});
            }
        }).then(res=>{
          console.log(res)
          _this.loading = false
        });
      }else{
        this.handleDonate(this.spList[this.currentIndex], this.currentIndex+1);
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .sps{
    width: 100%;
    padding: 15PX;
    border-radius: 15PX;
    color:#333;
    position: relative;
    background: #dadada;
    .close-tag {
      position: absolute;
      top: 15PX; right: 15PX;
    }
    .title {
      margin-bottom: 15PX;
    }
    .list {
      .item {
        padding: 15PX;
        margin-bottom: 15PX;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .img {
          .icon {
            height: 38PX;
          }
        }
        .price {
          padding-top: 7PX;
        }
      }.on {
        background: @primary-color-shadow;
        color: #fff;
        font-weight: bold;
      }
    }
    .bct-mixed {
      display: flex;
      justify-content: space-around;
      margin: 15px auto;
      .item {
        // padding-left: 80px;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
          display: block;
          content: '';
          width: 30px;
          height: 30px;
          margin-right: 15px;
          border-radius: 50%;
          background: transparent;
          border: 1px solid #333;
        }
      }.on {
        color: @primary-color;
        font-weight: bold;
        &::before {
          background: @primary-color;
          
        }
      }
    }
  }
</style>
